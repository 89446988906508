/******** borderSize *******/
/******** borderStyle *******/
/******** radius *******/
/******** shadow distance *******/
/******** size *******/
/******** spacing *******/
/******** shadow *******/
/******** opacity *******/
/******** fontSize *******/
/******** fontWeight ********/
/******** Primary *******/
/******** success *******/
/******** warning *******/
/******** danger *******/
/******** link *******/
/******** radius *******/
/********* icon hover *********/
body {
  --red-1: rgb(255,236,232);
  --danger-1: var(--red-1);
  --color:#131313;
  --bgColor:#f4f6fa;
  --grayFontColor:#7c8c9c;
  --sectionBgColor:#fff;
  /* --inputBgColor:rgba(236, 240, 246, 0.8); */
  --inputBgColor:#ECF0F6;
  --btnColor:linear-gradient(106.09deg, #FB7C56 22.39%, #FF3585 99.03%);
  --walletBtn:#13131314;
  --walletBg:#f4f6fa;
  --divider: rgba(19, 19, 19, 0.08);
  --shadow:rgba(0,0,0,0.15)
}
body[arco-theme='dark'] {
  --red-1: rgb(77,0,10);
  --danger-1: var(--red-1);
  --color:#fff;
  --bgColor:#020202;
  --grayFontColor:#7c8c9c;
  --sectionBgColor:#1C1C20;
  --inputBgColor:rgba(19, 19, 19, 1);
  --btnColor:linear-gradient(106.09deg, #FB7C56 22.39%, #FF3585 99.03%);
  --walletBtn:#1C1C20;
  --walletBg:#1C1C20;
  --divider: rgba(255, 255, 255, 0.08);
  --shadow:rgba(37,36,37,0.6)
}
/* body {
  --color-danger-light-1: rgb(var(--danger-1));
} */
