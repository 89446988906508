@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: GlobalFont;
  src: url('./assets/font/LexendDeca-Regular.ttf')
}
*{
  padding:0;
  margin: 0;
}
body {
  margin: 0;
  font-family:GlobalFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bgColor);
}
.layout-basic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: var(--bgColor); */
  color: var(--color);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  
}
.layout-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 1920px; */
  width: 100%;
  font-size: 16px;
  height: auto;
  /* margin-bottom: 72px; */
  margin-bottom: 36px;
}
.header{
  flex: 1;
}
.darkBg{
  background-image: url('../src/assets/img/darkBg.svg');
}
.lightBg{
  background-image: url('../src/assets/img/lightBg.svg');
}
.ModuleBg{
  /* background-image: url('../src/assets/img/ModuleBg.svg');
  background-position: center;
  background-repeat: repeat-y;
  background-size: 490px auto; */
}
.ModuleBgInner{
  width: 464px;
  background-image: url('../src/assets/img/ModuleBg.svg');
  background-position: center;
  background-repeat: repeat-y;
  background-size: inherit;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 3px solid rgba(176,169,190,0.6);
}
.ModuleDark{
  position: relative;
}
.ModuleDark::after{
  display: inline-block;
  content: "";
  background-image: url('../src/assets/img/ModuleDark.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 490px;
  height: 27px;
}
.ModuleLight{
  position: relative;
}
.ModuleLight::after{
  display: inline-block;
  /* content: url('../src/assets/img/ModuleLight.svg'); */
  content: "";
  background-image: url('../src/assets/img/ModuleLight.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 490px;
  height: 27px;
}
/* .ModuleLight{
  background-image: url('../src/assets/img/ModuleLight.svg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 490px 27px;
}
.ModuleDark{
  background-image: url('../src/assets/img/ModuleDark.svg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 490px 27px;
} */
/* .headerItem{
  flex: 1;
} */
.menu{
  width: 100%;
}
.dropdown-demo .arco-dropdown-popup-visible .arco-icon-down {
  transform: rotate(180deg);
}
.main{
  /* width: 490px; */
  min-height:calc(100vh - 72px);
}
.w-490{
  width: 490px;
}
.w-520{
  width: 520px;
}
.w-1200{
  width: 1200px;
}
.w-30{
  width: 30px;
}
._flex{
  display: flex!important;
}
/* .fontColor{
  color: var(--color)!important;
} */
.verticalAlign{
  vertical-align: middle!important;
}
.disabledNoGray{
  pointer-events: none;
}
.nodataClaim{
  pointer-events: none;
  background: linear-gradient(106.09deg, rgba(251, 124, 86, 0.4) 22.39%, rgba(255, 53, 133, 0.4) 99.03%)!important;
}
.disabled{
  pointer-events: none;
  background: #9fa4b3!important;
}
.gray{
  font-size: 14px;
  color: var(--grayFontColor)!important;
}
.grayNoFont{
  color: var(--grayFontColor)!important;
}
.fontColor{
  color: var(--color)!important;
  transition:none!important
}
.white{
  color: white!important;
}
.bgWhite{
  background-color: white!important;
  color: #000;
  font-weight: 400;
}
.-cbwsdk-css-reset{
  display: none!important;
}
.font15{
  font-size: 15px!important;
}
.font18{
  font-size: 18px;
}
.font20{
  font-size: 20px;
}
.font22{
  font-size: 22px;
}
.font26{
  font-size: 26px;
}
.font28{
  font-size: 28px;
}
.leading-normal{
  line-height: normal;
}
._shadowBig{
  box-shadow: 0 16px 32px -8px var(--shadow);
}
._shadowSmall{
  box-shadow: 0 1px 3px 0px var(--shadow);
}
.MenuShadow{
  border-radius: 12px!important;
  box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.15)!important;
  border: none!important;
}
.walletBtn{
  background: var(--walletBtn);
  color: var(--color);
  font-weight: 600;
}
.walletBg{
  background-color: var(--walletBg)!important;
  border-radius: 16px;
}
.connectWallet{
  color: #fff;
  background:var(--btnColor);
}
.sectionBg{
  background-color: var(--sectionBgColor)!important;
  border-radius: 16px;
}
.sectionBgNoRadius{
  background-color: var(--sectionBgColor)!important;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.TabBg{
  background-color: var(--sectionBgColor)!important;
  border-radius: 12px;
}
.secTitle{
  color:#B4B4BF;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 1rem;
}
.inputBg{
  background-color: var(--inputBgColor);
  border-radius: 12px;
}
.inputBg .arco-select-view{
  padding: 0 0 0 4px!important;
  transition: none;
}
.inputBg .arco-input-inner-wrapper{
  transition: none;
  padding-right: 0!important;
}
.btnBg{
  color: #fff!important;
  background:var(--btnColor);
}
.fontLinear{
  background: linear-gradient(to right, #FB7C56, #FF3585);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.colorFontLinear{
  color: linear-gradient(to right, #FB7C56, #FF3585);
}
.borderBg{
  border: 1px solid transparent!important;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, var(--sectionBgColor), var(--sectionBgColor)), linear-gradient(90deg, #FB7C56, #FF3585)!important;
}
.arco-modal .arco-modal-close-icon{
  font-size: 16px!important;
}
.IconSync{
  position: relative;
  width: 46px;
  height: 46px;
  border-radius:50%;
  /* border:2px solid var(--sectionBgColor);
  background-color: var(--inputBgColor); */
}
.IconSyncStake{
  width: 58px;
  height: 58px;
  border-radius:50%;
  /* background-color: var(--inputBgColor); */
  background-color: #fff;
}
.IconArrowRight{
  width: 14px;
  height: 14px;
  border-radius:50%;
  background-color: var(--inputBgColor);
  color: var(--color);
}
@keyframes twink {
  0% {
    opacity: 0.2;
    transform: scale(1);
  }

  50% {
    opacity: 0.5;
    transform: scale(1.12);
  }

  100% {
    opacity: 0.2;
    transform: scale(1);
  }
}
.twinkling {
  animation: twink 0.8s linear 1 forwards;
}

@keyframes SwapTransfer{
    0% {
      transform: rotate(0deg)
    }
    100% {
      transform: rotate(360deg)
    }
}

.rotate{
  animation: SwapTransfer 1.2s linear 0s infinite;
};

.mb0{
  margin-bottom: 0!important;
}
.mb2-5{
  margin-bottom:6px;
}
.text-32{
  font-size: 32px;
}
.text-22{
  font-size: 22px;
}
/* .balance{
  font-size: 22px;
  font-weight: 500;
} */
.dropdown-demo .arco-dropdown-open .arco-icon-down {
  transition: all ease-in-out 0.3s;
  transform: rotate(180deg);
}
.dropdown-demo .arco-icon-down {
  transition: all ease-in-out 0.3s;
  transform: rotate(0deg);
}
.changeNetwork{
  background-color:rgba(220, 53, 69, 0.24);
}
.success{
  color: #00C189!important;
}
.pending{
  color: #F9C210!important;
}
.active{
  color: #3B82F6!important;
}
.pendingBg{
  color: #3B82F6!important;
}
.successBg{
  color: #00C189!important;
}
.failBg{
  color: #FF5656!important;
}
.readyClaim{
  color:rgba(0, 193, 137, 1)!important;
  background-color: rgba(0, 193, 137, 0.1)!important;
  border-radius: 12px!important;
}
.pendingClaim{
  color:rgba(249, 115, 22, 1)!important;
  background-color: rgba(249, 115, 22, 0.1)!important;
  border-radius: 12px!important;
}
.LinkClaim{
  font-size:24px;
  padding:4px;
  border-radius: 6px;
  color: rgba(59, 130, 246, 1)!important;
  background-color: rgba(59, 130, 246, 0.1);
}

.divider-demo-flex-content {
  display: flex!important;
  align-items: flex-start;
  justify-content: center;
  border-bottom:1px solid var(--divider);
}
.divider-demo-flex-content:last-child{
  border: none;
}
.divider{
  border-bottom:1px solid rgba(255, 255, 255, 0.08);
  ;
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
  font-size: 24px;
  margin-top: 2px;
}

.content {
  flex: 1;
  font-size: 12px;
  line-height: 20px;
  color: var(--color-text-2);
}

.footer{
  width: 100%;
  /* height: 98px; */
  padding: 9px 12px 9px 12px;
  margin-top: 34px;
  border-top:1px solid var(--divider);
  justify-content: space-between;
  background-color: var(--sectionBgColor);
}
.footerItem{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  color: var(--grayFontColor)
}
.more{
  color: var(--grayFontColor)
}
.more:hover{
  color: var(--color);
}
.grayColor{
  color: var(--grayFontColor)
}
.menuActive{
  color: var(--color);
}

.arco-input-group-addbefore, .arco-input-group-addafter{
  background-color: var(--inputBgColor)!important;
}
.arco-input-group-addbefore{
  width: 100px!important;
}
.arco-input-inner-wrapper{
  background-color: var(--inputBgColor)!important;
}

.card-custom-hover-style {
  transition-property: all;
}

.card-custom-hover-style:hover {
  transform: translateY(-4px);
}
.card-demo-in-grid .arco-card-header {
  border: none;
}
.arco-card-header .arco-card-header-extra{
  color:var(--color);
  font-size: 16px!important;
}
._arco-card-grid {
  box-shadow:none!important;
  border: 2px solid transparent;
}

.arco-modal{
border-radius: 16px!important;
Border:2px, 0px, 0px, 0px!important;
Padding:24px!important;
/* Gap:16px!important; */
}
.wallet{
  padding-top:6px!important
}
.wallet .arco-modal-header{
  padding-left:0!important;
}
.arco-modal-content{
  padding: 0!important;
}
.arco-modal-header{
  border: none!important;
}
.arco-select-size-default.arco-select-single .arco-select-view{
  height: 40px;
  line-height: 40px;
  color: var(--color)!important;
}
.arco-select-single .arco-select-view-selector{
  align-items: center;
}
.arco-select-popup .arco-select-option{
  color: var(--color)!important;
  padding: 0 0 0 4px!important;
}
.arco-input{
  text-align: right;
  font-size: 20px!important;
  font-weight: 600;
  /* padding: 0 12px!important; */
}
.arco-form-item{
  margin: 0 !important;
}
.arco-menu-horizontal .arco-menu-inner{
  padding: 16px!important;
}

.logo .arco-menu-selected-label{
  display: none;
}
.MintSend .arco-form-label-item .arco-form-item-symbol{
  display: none;
}
.arco-form-extra{
  margin: 0!important;
  font-size: 14px!important;
}
.MintSend .arco-form-extra{
  line-height: 25px!important;
  font-size: 14px!important;
  /* margin: 0 0 0 -32px!important; */
}
.MintSend .arco-form-message{
  float: right;
  /* text-align: end; */
  line-height: 25px!important;
  font-size: 14px!important;
  position: absolute;
  right: 0;
  background-color: var(--inputBgColor);
}
.MintSend .arco-form-message + .arco-form-extra{
  margin: 0!important;
  /* margin: 0 0 0 -32px!important; */
}
.MintReceive .arco-input-disabled{
  color: var(--color);
}
.MintReceive  .arco-input-disabled{
  cursor: not-allowed;
  color: var(--color)!important;
  -webkit-text-fill-color: var(--color)!important;
}

/* withdrwal page */
.withdrwal .arco-form-label-item .arco-form-item-symbol{
  display: none;
}
.withdrwal .arco-form-extra{
  line-height: 25px!important;
  font-size: 14px!important;
  /* margin: 0 0 0 -32px!important; */
  margin: 0 !important;
}
.withdrwal .arco-form-message{
  /* float: right;
  line-height: 25px!important;
  font-size: 14px!important; */
  float: right;
  line-height: 25px!important;
  font-size: 14px!important;
  position: absolute;
  right: 0;
  background-color: var(--inputBgColor);
}
.withdrwal .arco-form-message + .arco-form-extra{
  /* margin: 0 0 0 -32px!important; */
  margin: 0!important;
}
/* Stake page */
.stake{
  margin:0!important;
}
.stake .arco-form-label-item .arco-form-item-symbol{
  display: none;
}
.stake .arco-form-extra{
  line-height: 25px!important;
  font-size: 14px!important;
  margin: 0!important;
}
.stake .arco-form-message{
  float: right;
  /* text-align: end; */
  line-height: 25px!important;
  font-size: 14px!important;
  position: absolute;
  right: 0;
  background-color: var(--inputBgColor);
}
.stake .arco-input-disabled{
  color: var(--color);
}
.stake  .arco-input-disabled{
  cursor: not-allowed;
  color: var(--color)!important;
  -webkit-text-fill-color: var(--color)!important;
}
.unStake{
  margin:0!important;
}
.unStake .arco-form-label-item .arco-form-item-symbol{
  display: none;
}
.unStake .arco-form-extra{
  line-height: 25px!important;
  font-size: 14px!important;
  margin: 0!important;
}
.unStake .arco-form-message{
  float: right;
  /* text-align: end; */
  line-height: 25px!important;
  font-size: 14px!important;
  position: absolute;
  right: 0;
  background-color: var(--inputBgColor);
}

.unStake .arco-input-disabled{
  color: var(--color);
}
.unStake  .arco-input-disabled{
  cursor: not-allowed;
  color: var(--color)!important;
  -webkit-text-fill-color: var(--color)!important;
}
/* .arco-tabs{
  overflow: auto!important;
} */
.arco-select-popup .arco-select-option{
  display: flex;
  align-items: center;
  /* line-height: 40px!important; */
}
.arco-tabs{
  overflow: visible!important;
}
.arco-tabs-content{
  overflow: visible!important;
}
/* .arco-tabs-content .arco-tabs-content-item{
  overflow: visible!important;
} */
.arco-tabs-content .arco-tabs-content-item.arco-tabs-content-item-active{
  overflow: visible!important;
}
div.arco-typography, p.arco-typography{
  margin: 0!important;
}
/* H5 */
.addWallet{
  width: 16px!important;
  height: 16px!important;
}
.withDrawType{
  width: 24px!important;
  height:24px!important
}
.AddTokens{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  color: rgba(30, 41, 59, 1);
  ;
}
@media (max-width: 767px) {
  .layout-content {
    width: 100vw;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 12px;
    margin:0;
  }
  .w-490{
    width: 100%;
  }
  .w-520{
    width: 100%;
  }
  .w-125percent{
    width: 125%!important;
  }
  .arco-modal{
    width: calc(100% - 32px)!important;
    padding: 12px!important;
  }
  .headerMenu{
    display: none;
  }
  .ModuleBgInner{
    width: calc(100% - 18px);
  }
  .ModuleLight::after,.ModuleDark::after{
    width: 100%;
    height:calc((100vw - 45px) * (27 / 490))
  }
  .menu_pc{
    display: none!important;
  }
  .IconSyncStake{
    width: 52px;
    height: 52px;
    border-radius:50%;
    /* background-color: var(--inputBgColor); */
  }
  /* .IconArrowRight{
    width: 14px;
    height: 14px;
    border-radius:50%;
    background-color: var(--inputBgColor);
  } */
  .MintSend .arco-form-message{
    font-size: 12px!important;
  }
  .withdrwal .arco-form-message{
    font-size: 12px!important;
  }
  .stake .arco-form-message{
    font-size: 12px!important;
  }
  .AddTokens{
    display: none;
  }
  /* .arco-icon-h5{
    width: 16px!important;
    height: 16px!important;
    margin-top: 4px;
  } */
  .arco-card-size-default .arco-card-body{
    padding: 8px!important;
  }
  .arco-card-size-default .arco-card-header{
    padding: 8px!important;
  }
  .arco-card-size-default .arco-card-body .arco-col{
    font-size:10px!important;
  }
  .arco-input-inner-wrapper{
    padding-left: 6px;
  }
  .arco-input-group-addbefore{
    width: 75px!important;
    font-size: 14px;
  }
  .arco-modal-wrapper .arco-modal{
    padding-top:6px!important;
  }
  .arco-card-header .arco-card-header-extra{
    font-size: 12px!important;
  }
  .withDrawType{
    width: 20px!important;
    height: 20px!important
  }
  .avatar {
    width: 20px;
    height: 20px;
    margin-top: 3px;
  }
}